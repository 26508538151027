import { Product } from "src/redux/apiTypes";
import useLocalization from 'src/services/localization/useLocalization';
import useToggles from "src/services/toggles/useToggles";
import { filterObject } from "src/utils/js-helpers/filterObject";
import { truncateObject } from "src/utils/js-helpers/truncateObject";
import getBadgesLocalizations from "src/utils/product/getBadgesLocalizations";

export interface ProductBadgeParams {
  is_hit: Product['is_hit'];
  is_new_product:  Product['is_new_product'];
  discount: Product['discount'];
  bundle: Product['bundle'];
  own_brand_data: Product['own_brand_data'];
  price_wholesale: Product['price_wholesale'];
}

export const useProductBadges = (product: Product | ProductBadgeParams, maxLength: number | null = null) => {
  const localize = useLocalization();
  const { BUNDLES_AVAILABLE } = useToggles();

  const badges = getBadgesLocalizations(
    {
      isHit: product.is_hit,
      isNovelty: product.is_new_product,
      discountValue: product.discount?.status && product.discount?.value,
      bundleValue: product.bundle,
      isBulk: product.price_wholesale?.length > 0,
      ownBrandLogo: product.own_brand_data?.image,
    },
    localize,
    BUNDLES_AVAILABLE,
  );

  const badgesToShow = typeof maxLength === 'number'
    ? truncateObject(filterObject(badges), maxLength)
    : badges;

  return badgesToShow;
};
